import shoppingCartLogo from "../../../../../images/favoritespng.png";
import s from "./Favorites.module.scss";
import { Link } from "react-router-dom";

function Favorites() {
  return (
    <>
      <Link to="favorites">
        <img
          src={shoppingCartLogo}
          alt="Shopping Cart"
          className={s.FavoritesImage}
        />
      </Link>
    </>
  );
}

export default Favorites;
