import CardsList from "./CardsList/CardsList";
import s from './Main.module.scss'

function Main ( { addToCart ,addToFavorites, deleteFavorite }) {
        return (
            <main className={s.main}>
                <p className={s.mainTitle}>DO YOU WANT TO FEEL ENEGRGIZET AND STRONG?</p>
                <CardsList addToFavorites={addToFavorites} addToCart={addToCart} deleteFavorite={deleteFavorite}/>
            </main>
        )
    
}

export default Main;