import { configureStore } from "@reduxjs/toolkit";
import cardsSlise from "./Slices/cardsSlise";
import  modalSlice  from "./Slices/modalSlice";
import  shoppingCardSlice  from "./Slices/shoppingCardSlice";


export const store = configureStore({
    reducer: {
        shoppingCart: shoppingCardSlice,
        modals: modalSlice,
        cards: cardsSlise,
    },
})

