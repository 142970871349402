import s from "./CardButton.module.scss";

function CardButton({ handleAddedItem }) {
  return (
    <button className={s.mainButton} onClick={handleAddedItem}>
      ADD TO CART
    </button>
  );
}

export default CardButton;
