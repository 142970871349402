import s from "./Footer.module.scss";
import FooterTitle from "./FooterTitle/FooterTitle";

function Footer() {
  return (
    <footer className={s.footer}>
      <FooterTitle />
    </footer>
  );
}

export default Footer;
