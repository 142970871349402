import DeleteButton from "./DeleteButton";
import s from "./ShoppingCardItem.module.scss";

function ShoppingCardItem({
  image,
  name,
  price,
  artikle,
  handleOpeningSecondModal,
}) {
  return (
    <div className={s.card}>
      <img className={s.cardImage} src={image} alt="Energy Drink" />
      <div className={s.cardInfo}>
        <div className={s.CardTitle}>{name}</div>
        <div className={s.cardMain}>
          <div className={s.price}>{price} $</div>
          <DeleteButton
            handleOpeningSecondModal={handleOpeningSecondModal}
            artikle={artikle}
          />
        </div>
      </div>
    </div>
  );
}

export default ShoppingCardItem;
