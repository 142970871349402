import { useState, useEffect } from "react";
import s from './Card.module.scss';
import CardButton from "./CardButton/CardButton";

const Card = ({ artikle, image, name, price, addToCart, addToFavorites, deleteFavorite }) => {
    const [isAddedToFavorite, setIsAddedToFavorite] = useState(false);

    useEffect(() => {
        const addedToFavorites = localStorage.getItem('addedToFavorites');

        if (addedToFavorites) {
            const favorites = JSON.parse(addedToFavorites);
            const isAddedToFavorite = favorites.some(item => item.artikle === artikle);
            setIsAddedToFavorite(isAddedToFavorite);
        }
    }, [artikle]);

    const handleAddToFavIcon = () => {
        const addedToFavorites = localStorage.getItem('addedToFavorites');
        let favorites = [];

        if (addedToFavorites) {
            favorites = JSON.parse(addedToFavorites);
        }

        if (isAddedToFavorite) {
            favorites = favorites.filter(item => item.artikle !== artikle);
        } else {
            favorites.push({ artikle });
        }

        localStorage.setItem('addedToFavorites', JSON.stringify(favorites));
        setIsAddedToFavorite(prevState => !prevState);
    };

    const handleAddToFavorite = () => {
        addToFavorites(image, name, price, artikle);
    };

    const handleAddedItem = () => {
        addToCart(image, name, price, artikle);
    };

    const handleDeleteItem = () => {
        const addedToFavorites = localStorage.getItem('addedToFavorites');
        let favorites = [];

        if (addedToFavorites) {
            favorites = JSON.parse(addedToFavorites);
            favorites = favorites.filter(item => item.artikle !== artikle);
            localStorage.setItem('addedToFavorites', JSON.stringify(favorites));
        }
        deleteFavorite(artikle);
    };

    return (
        <div className={s.card}>
            <span onClick={handleAddToFavIcon} className={s.FavoriteIcon}>
                {isAddedToFavorite ? <div onClick={handleDeleteItem}>&#128154;</div> : <div onClick={handleAddToFavorite}>&#129293;</div>}
            </span>

            <img className={s.cardImage} src={image} alt="Energy Drink" />
            <div className={s.cardInfo}>
                <div className={s.CardTitle}>{name}</div>
                <div className={s.cardMain}>
                    <div className={s.price}>{price} $</div>
                    <CardButton handleAddedItem={handleAddedItem} addToCart={addToCart} className={s.addCardButton} />
                </div>
            </div>
        </div>
    );
}

export default Card;
