import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  cards: [],
};

export const getCards = createAsyncThunk(
  "cards/getCards",
  async (_, { rejectWithValue, dispatch }) => {
    const result = await axios.get("/Cards.json");
    dispatch(setCards(result.data))
  }
);

export const cardsSlise = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setCards: (state, action) => {
      state.cards = action.payload;
    },
  }
});

export const { setCards } = cardsSlise.actions;
export default cardsSlise.reducer