import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItemsAmount: 0,
  addedToShoppingCart: [],
  shoppingCard: [],
};

export const shoppingCardSlice = createSlice({
  name: "shoppingCard",
  initialState,
  reducers: {
    setCartItemsAmount(state, action) {
      if (action.payload) {
        state.cartItemsAmount = action.payload;
      }
      const shoppingCard = [
        ...state.shoppingCard,
        ...state.addedToShoppingCart,
      ];
      localStorage.setItem("ShoppingCard", JSON.stringify(shoppingCard));
      state.cartItemsAmount = shoppingCard.length;
    },

    setAddedToShoppingCart(state, action) {
      const { url, name, price, artikle } = action.payload;
      state.addedToShoppingCart = [
        { url, name, price, artikle },
      ];
    },
    clearAddedToShoppingCart(state) {
      state.addedToShoppingCart = [];
    },
    setShoppingCard(state, action) {
      if (action.payload) {
        state.shoppingCard = [...action.payload];
      } else {
      state.shoppingCard = [
        ...state.shoppingCard,
        ...state.addedToShoppingCart,
      ];
    }
    state.cartItemsAmount = state.shoppingCard.length;

    },

    clearShoppingCard(state, action) {
      console.log("Client Info =====>>>>>",  JSON.stringify(action.payload));
      console.log("Shopping Card =====>>>>>>",  JSON.stringify(state.shoppingCard));
      state.shoppingCard = []
      localStorage.setItem("ShoppingCard", JSON.stringify(state.shoppingCard))
      state.cartItemsAmount = state.shoppingCard.length;
    }
  },
});

export const {
  setCartItemsAmount,
  setAddedToShoppingCart,
  clearAddedToShoppingCart,
  setShoppingCard,
  clearShoppingCard,
} = shoppingCardSlice.actions;

export default shoppingCardSlice.reducer;
