import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddedToShoppingCart,
  setCartItemsAmount,
  clearAddedToShoppingCart,
  setShoppingCard,
} from "./store/Slices/shoppingCardSlice";
import Modal from "./components/ui/Modal/Modal";
import s from "./App.module.scss";
import Header from "./components/ui/Header/Header";
import Main from "./components/ui/Main/Main";
import FavoritesMain from "./components/ui/Header/HeaderNav/Favorites/FavoritesMain";
import ShoppingCartMain from "./components/ui/Header/HeaderNav/ShoppingCart/ShoppingCartMain";
import {
  setIsFirstModalOpened,
  setSecondModalOpened,
  setCloseModal,
} from "./store/Slices/modalSlice";
import { getCards } from "./store/Slices/cardsSlise";

function App() {
  const dispatch = useDispatch();
  const shoppingCard = useSelector((state) => state.shoppingCart.shoppingCard);
  const [addedToFavorites, setAddedToFavorites] = useState([]);
  const [favoritesAmount, setFavoritesAmount] = useState(0);
  const [targetItem, setTargetItem] = useState("");

  useEffect(() => {
    const shoppingCard = localStorage.getItem("ShoppingCard");
    const favorites = localStorage.getItem("Favorites");

    if (shoppingCard) {
      dispatch(setShoppingCard(JSON.parse(shoppingCard)));
      dispatch(setCartItemsAmount(JSON.parse(shoppingCard).length));
    }
  
    if (favorites) {
      setAddedToFavorites(JSON.parse(favorites));
      setFavoritesAmount(JSON.parse(favorites).length);
    }

    dispatch(getCards());
  }, []);

  const handleOpeningFirstModal = (url, name, price, artikle) => {
    dispatch(setIsFirstModalOpened());
    dispatch(setAddedToShoppingCart({ url, name, price, artikle }));
  };

  const handleOpeningSecondModal = (artikle) => {
    dispatch(setSecondModalOpened());
    setTargetItem(artikle);
  };

  const handleCloseModal = () => {
    dispatch(setCloseModal());
    dispatch(clearAddedToShoppingCart());
  };

  const handleCartAmount = () => {
    dispatch(setCartItemsAmount(shoppingCard.length));
    dispatch(setCloseModal());
    dispatch(setShoppingCard());
  };

  const handleAddToFavorites = (url, name, price, artikle) => {
    const isItemExist = addedToFavorites.some(
      (item) => item.artikle === artikle
    );

    if (!isItemExist) {
      setAddedToFavorites((prevFavorites) => [
        ...prevFavorites,
        { url, name, price, artikle },
      ]);

      setFavoritesAmount((prevAmount) => prevAmount + 1);
    }

    localStorage.setItem("Favorites", JSON.stringify(addedToFavorites));
  };

  const handleDeleteFromFavorites = (artikle) => {
    const updatedFavorites = addedToFavorites.filter(
      (item) => item.artikle !== artikle
    );

    setAddedToFavorites(updatedFavorites);
    setFavoritesAmount((prevAmount) => prevAmount - 1);

    localStorage.setItem("Favorites", JSON.stringify(updatedFavorites));
  };

  const handleDeleteFromShoppingCard = () => {
    const updatedCard = shoppingCard.filter(
      (item) => item.artikle !== targetItem
    );
    dispatch(setShoppingCard(updatedCard));
    localStorage.setItem("ShoppingCard", JSON.stringify(updatedCard));
    dispatch(setCloseModal());
  };
  const isFirstModalOpened = useSelector(
    (state) => state.modals.isFirstModalOpened
  );
  const isSecondModalOpened = useSelector(
    (state) => state.modals.isSecondModalOpened
  );

  return (
    <div className={s.App}>
      <Routes>
        <Route path="/" element={<Header favoritesAmount={favoritesAmount} />}>
          <Route
            index
            element={
              <Main
                addToFavorites={handleAddToFavorites}
                deleteFavorite={handleDeleteFromFavorites}
                addToCart={handleOpeningFirstModal}
              />
            }
          />
          <Route
            path="shopping-cart"
            element={
              <ShoppingCartMain
                main={shoppingCard}
                handleDeleteFromShoppingCard={handleDeleteFromShoppingCard}
                handleOpeningSecondModal={handleOpeningSecondModal}
              />
            }
          />
          <Route
            path="favorites"
            element={
              <FavoritesMain
                addToFavorites={handleAddToFavorites}
                deleteFavorite={handleDeleteFromFavorites}
                addToCart={handleOpeningFirstModal}
                main={addedToFavorites}
              />
            }
          />
          <Route path="" />
        </Route>
      </Routes>
      <Modal
        onClick={handleCloseModal}
        closeButton={true}
        isOpened={isFirstModalOpened}
        actions={
          <>
            <button onClick={handleCartAmount} className={s.ConfirmAddBtn}>
              Ok
            </button>
            <button className={s.ConfirmAddBtn} onClick={handleCloseModal}>
              Cancel
            </button>
          </>
        }
        header="FLASH ENERGY"
        text={"ADD ITEM TO SHOPPING CART?"}
        backgroundColor={"rgba(31, 29, 29, 1)"}
        headerBackgroundColor={"rgba(20, 18, 18, 1)"}
      ></Modal>
      <Modal
        backgroundColor={"rgba(31, 29, 29, 1)"}
        headerBackgroundColor={"rgba(20, 18, 18, 1)"}
        closeButton={true}
        onClick={handleCloseModal}
        isOpened={isSecondModalOpened}
        header="Do you want to delete this file?"
        text={"Delete item. Are You sure?"}
        actions={
          <>
            <button
              className={s.confirmModalButton}
              onClick={handleDeleteFromShoppingCard}
            >
              Confirm
            </button>
            <button className={s.confirmModalButton} onClick={handleCloseModal}>
              Cancel
            </button>
          </>
        }
      ></Modal>
    </div>
  );
}

export default App;
