import { createSlice } from "@reduxjs/toolkit";

const initialState = {
isFirstModalOpened: false,
isSecondModalOpened: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    
    setIsFirstModalOpened(state) {
        state.isFirstModalOpened = true;
        state.isSecondModalOpened = false
     },
     setSecondModalOpened(state) {
        state.isFirstModalOpened = false;
        state.isSecondModalOpened = true
     },
     setCloseModal(state) {
        state.isFirstModalOpened = false;
        state.isSecondModalOpened = false
     }

  },
});

export const { setIsFirstModalOpened, setSecondModalOpened, setCloseModal } = modalSlice.actions;
export default modalSlice.reducer;
