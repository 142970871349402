import Ordering from "./Ordering/Ordering";
import ShoppingCardItem from "./ShoppingCardItem";
import s from "./ShoppingCartMain.module.scss";

function ShoppingCartMain({ main, handleOpeningSecondModal }) {
  const renderedShoppingCards = main.map((card) => {
    return (
      <ShoppingCardItem
        key={card.artikle}
        name={card.name}
        image={card.url}
        price={card.price}
        artikle={card.artikle}
        handleOpeningSecondModal={handleOpeningSecondModal}
      />
    );
  });
  return (
    <div className={s.ShoppingCartMainWrapper}>
      <div className={s.ShoppingCartMain}>
        <div className={s.shoppingCartList}>{renderedShoppingCards}</div>
        <div className={s.about}>
          <Ordering/>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCartMain;
