import Card from "../../../Main/Card/Card";
import s from "./FavoritesMain.module.scss";

function FavoritesMain({ main, addToFavorites, deleteFavorite, addToCart }) {
  const renderedFavoritesCards = main.map((card) => {
    return (
      <Card
        key={card.artikle}
        name={card.name}
        image={card.url}
        price={card.price}
        artikle={card.artikle}
        addToCart={addToCart}
        addToFavorites={addToFavorites}
        deleteFavorite={deleteFavorite}
      />
    );
  });
  return (
    <div className={s.FavoritesMainWrapper}>
      <div className={s.FavoritesMain}>
        <div className={s.FavoritesCartList}>{renderedFavoritesCards}</div>
        <div className={s.about}></div>
      </div>
    </div>
  );
}

export default FavoritesMain;
