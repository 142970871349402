import s from "./DeleteButton.module.scss";

function DeleteButton({ handleOpeningSecondModal, artikle }) {
  const handleClick = (e) => {
    handleOpeningSecondModal(artikle);
  };
  return (
    <button className={s.mainButton} onClick={handleClick}>
      DELETE ITEM
    </button>
  );
}
export default DeleteButton;
