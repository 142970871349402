import s from "./FooterTitle.module.scss";
import footerTitle from "../../../../images/FooterTitle.png";
function FooterTitle() {
  return (
    <div className={s.FooterTitle}>
      <img className={s.footerImage} src={footerTitle} alt="drifting" />
      <div className={s.titleDescriptioon}>
        DRIFT MASTERS EUROPEAN CHAMPIONSHIP
      </div>
      <button className={s.footerButton}>EVENT DETAILS</button>
    </div>
  );
}

export default FooterTitle;
