import logo from "../../../../images/logo.png";
import Login from "../Login/Login";
import Favorites from "./Favorites/Favorites";
import s from "./HeaderNav.module.scss";
import ShoppingCart from "./ShoppingCart/ShoppingCart";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function HeaderNav({ addedCardsToCart, favoritesAmount }) {
  const cartAmount = useSelector((state) => state.shoppingCart.cartItemsAmount);
  return (
    <nav className={s.headerNav}>
      <ul className={s.navList}>
        <li className={s.navItem}>PRODUCTION</li>
        <li className={s.navItem}>TV</li>
        <li className={s.navItem}>ABOUT US</li>
        <Link to="/">
          <img className={s.logo} src={logo} alt="Flash Energy" />
        </Link>
        <li className={s.navItem}>NEW</li>
      </ul>
      <div className={s.userNav}>
        <Login />
        <Favorites />
        {favoritesAmount}
        <ShoppingCart addedCardsToCart={addedCardsToCart} />
        <div className={s.cartAmount}>{cartAmount}</div>
      </div>
    </nav>
  );
}

export default HeaderNav;
