import s from "./Ordering.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearShoppingCard } from "../../../../../../store/Slices/shoppingCardSlice";
import { useDispatch } from "react-redux";

const initialValues = {
  name: "",
  surename: "",
  age: "",
  adress: "",
  phone: "",
}; 


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function Ordering(props) {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(clearShoppingCard(values));
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[^0-9]*$/, "Invalid name")
      .required("Required"),
    surename: Yup.string()
      .matches(/^[^0-9]*$/, "Invalid surename")
      .required("Required"),
    age: Yup.number().required("Required"),
    adress: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div className={s.ordering}>
      <form className={s.form} onSubmit={formik.handleSubmit}>
        <label className={s.labelForm} htmlFor="name">
          Name
        </label>
        <input
          className={s.formInput}
          type="text"
          id="name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          onBlur={formik.handleBlur}
        />
        {formik.errors.name && (
          <div className={s.formError}>
            {formik.touched.name && formik.errors.name}
          </div>
        )}
        <label className={s.labelForm} htmlFor="surename">
          SureName
        </label>
        <input
          className={s.formInput}
          type="text"
          id="surename"
          name="surename"
          onChange={formik.handleChange}
          value={formik.values.surename}
          onBlur={formik.handleBlur}
        />
        {formik.errors.surename && (
          <div className={s.formError}>
            {formik.touched.surename && formik.errors.surename}
          </div>
        )}

        <label className={s.labelForm} htmlFor="age">
          Age
        </label>
        <input
          className={s.formInput}
          type="number"
          id="age"
          name="age"
          onChange={formik.handleChange}
          value={formik.values.age}
          onBlur={formik.handleBlur}
        />
        {formik.errors.age && (
          <div className={s.formError}>
            {formik.touched.age && formik.errors.age}
          </div>
        )}

        <label className={s.labelForm} htmlFor="adress">
          To Adress
        </label>
        <input
          className={s.formInput}
          type="text"
          id="adress"
          name="adress"
          onChange={formik.handleChange}
          value={formik.values.adress}
          onBlur={formik.handleBlur}
        />
        {formik.errors.adress && (
          <div className={s.formError}>
            {formik.touched.adress && formik.errors.adress}
          </div>
        )}
        <label className={s.labelForm} htmlFor="phone">
          Phone Number
        </label>
        <input
          className={s.formInput}
          type="tel"
          id="phone"
          name="phone"
          onChange={formik.handleChange}
          value={formik.values.phone}
          onBlur={formik.handleBlur}
        />
        {formik.errors.phone && (
          <div className={s.formError}>
            {formik.touched.phone && formik.errors.phone}
          </div>
        )}
        <button type="submit" className={s.formButton}>
          Order
        </button>
      </form>
    </div>
  );
}

export default Ordering;
