import { useSelector } from "react-redux";
import Card from "../Card/Card";
import s from "./CardsList.module.scss";

function CardsList ({ addToCart, addToFavorites, deleteFavorite }) {
  const cards = useSelector((state) => state.cards.cards)
  const renderedCards = cards.map((card) => {
    return (
      <Card
        key={card.artikle}
        name={card.name}
        image={card.url}
        price={card.price}
        artikle={card.artikle}
        addToCart={addToCart}
        addToFavorites={addToFavorites}
        deleteFavorite={deleteFavorite}
      />
    );
  });

  return ( <div className={s.cardsList}>{renderedCards}</div> );
}

export default CardsList;
