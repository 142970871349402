import HeaderNav from "./HeaderNav/HeaderNav";
import s from "./Header.module.scss";
import titleImg from "../../../images/TitleImage.png";
import logoSec from "../../../images/logoSec.png";
import logoFirst from "../../../images/logoFirst.png";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";

function Header({ addedCardsToCart, favoritesAmount }) {
  return (
    <>
      <header className={s.header}>
        <HeaderNav
          favoritesAmount={favoritesAmount}
          addedCardsToCart={addedCardsToCart}
        />
        <div className={s.title}>
          <img src={logoFirst} className={s.titleLogo} />
          <img src={titleImg} alt="Flash Energy" className={s.titleImage} />
          <img src={logoSec} alt="eawd" className={s.titleLogo} />
        </div>
      </header>
      <main className={s.main}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default Header;
