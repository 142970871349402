import { useSelector } from "react-redux";
import s from "./Modal.module.scss";

function Modal({
  header,
  closeButton,
  text,
  onClick,
  isOpened,
  actions,
  backgroundColor,
  headerBackgroundColor,
}) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return isOpened ? (
    <div className={s.modal} onClick={onClick}>
      <div
        className={s.modalContent}
        onClick={stopPropagation}
        style={{ backgroundColor }}
      >
        <div
          style={{ backgroundColor: headerBackgroundColor }}
          className={s.modalHeader}
        >
          {header}
          {closeButton ? (
            <p className={s.closeButton} onClick={onClick}>
              Х
            </p>
          ) : (
            false
          )}
        </div>
        <p className={s.modalText}>{text}</p>
        {actions && <div className={s.modalActions}>{actions}</div>}
      </div>
    </div>
  ) : (
    false
  );
}

export default Modal;
