import shoppingCartLogo from "../../../../../images/CartIcon.png";
import s from "./ShoppingCart.module.scss";
import { Link } from "react-router-dom";

function ShoppingCart() {
  return (
    <>
      <Link to="shopping-cart">
        <img
          src={shoppingCartLogo}
          alt="Shopping Cart"
          className={s.shoppingCartImage}
        />
      </Link>
    </>
  );
}

export default ShoppingCart;
